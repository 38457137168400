<template>
  <header class="header">
    <div class="header-top">
      <div class="c-box">
        <span>{{ $store.state.name }} ！</span>
        <span
          class="siginButton"
          @click="userSignout"
        ><i class="el-icon-switch-button" style="margin-right: 5px" />退出</span>
      </div>
    </div>
    <div class="header-content">
      <div class="c-box">
        <div class="content-left">
          <img src="~@/assets/logo/logo@3x.png" style="width: 100%;">
        </div>
        <div class="content-right">
          <el-menu class="menu" :default-active="active" mode="horizontal">
            <div v-for="(item, i) in menu" :key="i">
              <template v-if="item.child.length > 0">
                <el-submenu :index="item.id">
                  <template slot="title">
                    <div @click="goTo(item.router)">{{ item.name }}</div>
                  </template>
                  <div v-for="(itemC, k) in item.child" :key="k">
                    <el-menu-item
                      v-show="!itemC.hidden"
                      :index="itemC.id"
                      @click="goTo(itemC.router)"
                    >{{ itemC.name }}</el-menu-item>
                  </div>
                </el-submenu>
              </template>
              <template v-else>
                <el-menu-item :index="item.id" @click="goTo(item.router)">{{
                  item.name
                }}</el-menu-item>
              </template>
            </div>
          </el-menu>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { sigin, selectCurrencyRateByMoth } from '@/api/user.js';
export default {
  name: 'Header',
  props: {},
  data: function() {
    return {
      menu: this.$store.state.menu || [],
      active: ''
    };
  },
  watch: {
    '$route.path': function(newRoute) {
      this.init(newRoute);
    }
  },
  created() {
    this.init();
    this.getCurrentMonthTips();
  },
  methods: {
    getCurrentMonthTips() {
      if (sessionStorage.getItem('tipsFlag')) {
        return false;
      }
      selectCurrencyRateByMoth()
        .then(res => {
          if (res.code === 0 || res.code === -1) {
            sessionStorage.setItem('tipsFlag', true);
          }
          if (res.code == -1) {
            this.$alert(res.data, '提示', {
              confirmButtonText: '确定'
            });
          }
        });
    },
    init(path) {
      // 初速化class
      if (!path) {
        path = this.$route.path;
      }
      const menu = this.menu;
      for (const i in menu) {
        if (path == menu[i].router) {
          this.active = menu[i].id;
        }
        for (const k in menu[i].child) {
          if (path == menu[i].child[k].router) {
            this.active = menu[i].child[k].id;
          }
        }
      }
    },
    userSignout() {
      // 退出
      this.$router.push({ path: '/login' });
      this.$store.dispatch('newName', null);
      sessionStorage.clear();
      sigin(false);
    },
    goTo(path) {
      // 点击
      this.init(path);
      this.$router.push({ path });
      this.current = this.$router.history.current.path;
    }
  }
};
</script>

<style scoped>
.menu {
  display: flex;
}
.header {
  height: 82px;
  box-shadow: 0px 6px 7px 1px rgba(0, 10, 37, 0.07);
}
.header-top {
  background: #507EFF;
  color: #fff;
  height: 26px;
  line-height: 26px;
  font-size: 12px;
}
.siginButton {
  cursor: pointer;
  font-size: 12px;
  float: right;
  margin-right: 20px;
}
.header-content {
  height: 56px;
}
.header-content .c-box {
  position: relative;
  height: 100%;
}
.content-left {
  width: 63px;
  height: 100%;
  display: inline-block;
  display: flex;
  align-items: center;
}
.content-right {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 16px;
}
.el-menu.el-menu--horizontal {
  border: unset;
}
.content-right >>> .el-submenu__icon-arrow.el-icon-arrow-down {
  display: none;
}
.el-menu-item.is-active {
  color: #409EFF;
  border-bottom: 2px solid #409EFF;
}
.el-submenu.is-active {
  border-bottom: 2px solid #409EFF;
}
.el-submenu.is-active div{
  color: #409EFF;
}
.el-submenu.is-active /deep/ div.el-submenu__title {
  height: 54px;
  line-height: 56px;
}
.el-menu-item:focus {
  background-color: unset;
  color: #409EFF;
}
</style>
