<template>
  <div class="App">
    <Header />
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />

  </div>
</template>

<script>
import Header from '@/components/Header';
export default {
  name: 'Home',
  components: {
    Header
  },
  props: {

  },
  data() {
    return {
    };
  },
  mounted() {

  },
  methods: {
  }
};
</script>
<style scoped>
</style>
